<template>
<div>
  <editor
    api-key="m2w1lu787dyjizvo3s7jq88kx0d29932605cvce948xywugr"   
    output-format="html"
    :initial-value="value"        
    @input="updateValue" 
    v-model="content" 
    :disabled="disabled"
    :init="{
      height: 450,
      menubar: false,      
      branding: false,
      plugins: ['lists', 'paste'],
      paste_as_text: true,
      statusbar: false,
      toolbar: 'formatselect bold italic |  numlist bullist',
    }"
  />
</div>  
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
export default {
    name:"tinymce",
    props: ['value','disabled']        
    ,
    data(){
        return{
            content:""
        }
        },
  
    // created(){
    //   console.log(this.value)
    // },
    methods:{
      updateValue (){
        this.$emit("input", this.content)
      }, 
      keyUp(){        
        this.$emit("validateCharacters")         
      }       
    },
    watch:{
      value:function(val){
        // console.log(val,this.content)
        this.content = val;
        this.$emit("validateCharacters")
      },
      content:function(val){
        this.$emit("validateCharacters")
      }
    },
    components: {   
    editor: Editor,
  },
};
</script>