<template>
  <header>
    <div v-if="isLoaded" class="mobile-fix">
      <ul class="user-control">
        <li v-if="user.userId">
          <span
            class="dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- <i class="fas fa-user-graduate" v-if="user.role === 'Student'"></i><i class="fas fa-user-tie" v-if="user.role === 'Professional'"></i> -->
            {{ user.firstName }} {{ user.lastName }}
          </span>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <router-link to="/dashboard" class="dropdown-item">
              Dashboard
            </router-link>
            <router-link to="/user/profile-edit" class="dropdown-item">
              Edit profile
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="#" @click="logoutFromFirebase" class="dropdown-item"
              >Sign out</a
            >
          </div>
        </li>
        <li>
          <router-link to="/events/student-qr" v-if="user.role === 'Student'"
            ><img src="~@/assets/qr-code.png" class="qr_code" alt="" srcset=""
          /></router-link>
        </li>
        <!-- <li v-else>
         
           <router-link to="/register" class="btn btn-primary text-white">
              Register
            </router-link>
         
        </li>  -->
      </ul>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MasterHeader',
  props: {
    title: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['user']),

    isLoaded: function () {
      var vm = this;
      var result = true;
      // console.log(vm.user.userId)

      if (!vm.user.verified && vm.user.verified !== null) {
        // console.log("not verified");
        //vm.$router.push("/user/verify");
      } else if (vm.user.status === 'new' && vm.user.role === 'Professional') {
        vm.$router.push('/user/welcome');
      }
      return result;
    },
  },
  methods: {
    logoutFromFirebase() {
      this.$store.dispatch('signOutAction');
    },
  },
};
</script>
