<template>
  <div>
    <MasterHeader />
    <sign-up-modal v-if="hasNewSignupFlow"/>
    <div class="container">
      <!-- 1st row -->
      <div class="row">
        <div class="col-12">
          <router-link to="/collaboration/my" class="btn btn-sm btn-link"
            ><i class="fas fa-angle-left"></i> Go Back</router-link
          >
          <div class="collab-toolbar float-right">
            <button
              type="button"
              class="btn btn-sm btn-link"
              @click="changeStatus('approved')"
              v-if="collaborationDetails.status == 'published'"
            >
              Move to Draft
            </button>
            <button
              type="button"
              class="btn btn-sm btn-link"
              @click="changeStatus('deleted')"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <!-- 2nd row -->

      <div class="widget">
        <div
          v-if="isUserOwner && collaborationDetails.status !== 'collaborating'"
        >
          <!-- <div class="erow-cta" v-if="collaboration.status === 'draft'">
                  <h4><i class="far fa-life-ring"></i> Last step</h4> <span data-toggle="modal" data-target="#collabInvite">Invite your group</span> and <span @click="changeStatus('published')"><u>publish</u></span> your collaboration.
              </div> -->
          <div
            class="erow-cta"
            v-if="collaborationDetails.status === 'published'"
          >
            <h4>Published</h4>
            Your thesis topic is now visible to companies. If you wish to update
            the content or invite team member, please select 'Move to Draft'.
            This will also make the thesis topic unavailable to companies until
            you re-publish it.
          </div>
          <div
            class="erow-cta"
            v-else-if="collaborationDetails.status == 'review'"
          >
            <h4>IN REVIEW WITH EXCELERATE</h4>
            Please allow the Excelerate team 48 hours for the interview. You
            will not be able to edit the thesis topic while we are reviewing it
            - You will receive an email when it has been reviewed. If approved,
            we will publish your thesis for you.
          </div>
          <div
            class="erow-cta"
            v-else-if="collaborationDetails.status === 'approved'"
          >
            <h4>READY TO BE PUBLISHED</h4>
            Your thesis topic is not published and therefore not visible to
            companies.
          </div>

          <div class="widget-splash splash-line" v-else></div>
        </div>

        <div class="widget-header">
          <h4>Thesis Topic</h4>
        </div>
        <div class="widget-body widget-suggestion">
          <Loader
            v-if="!isLoaded || !collaborationDetails.createdDate"
            :times="2"
          />
          <div class="row" v-else>
            <!-- 1st column -->
            <div class="col-12 col-md-6 col-lg-8 main-body">
              <!-- nav menu -->
              <ol class="nav nav-pills">
                <li
                  class="nav-item"
                  v-for="(step, index) in steps"
                  :key="index"
                >
                  <a
                    :class="[
                      activeStep == index + 1 ? 'active' : '',
                      'nav-link',
                    ]"
                    @click="tabNavigation(index)"
                    aria-current="page"
                    ><span class="list-item-counter">{{ index + 1 }}</span
                    >{{ step }}
                    <i
                      v-if="collaborationDetails.stepsStatus[step] == 0"
                      class="fas fa-exclamation-circle color-red"
                    ></i>
                    <i
                      v-if="collaborationDetails.stepsStatus[step] == 1"
                      class="fas fa-check color-green"
                    ></i
                  ></a>
                </li>
              </ol>
              <!--content activeStep 1--->
              <div v-if="activeStep == 1">
                <p>
                  To get your thesis topic in front of companies you need to
                  fill out the following sections. Go through all 6 steps and
                  once it is all filled out you can send your thesis topic for
                  review with the Excelerate team. The review should not take
                  more than 48hours for us to complete.
                </p>
                <div
                  class="embed-responsive embed-responsive-21by9 col-12 col-md-8 mt-5 mx-auto"
                >
                  <iframe
                    src="https://player.vimeo.com/video/600589215?h=2e1c172b40&color=ff0179&title=0&byline=0&portrait=0"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <!-- content -->
                <div class="my-4 form-group">
                  <label>Collaboration title</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Your thesis title"
                    v-model="collaborationDetails.title"
                    :class="{
                      'limit-exceeded':
                        titleCharacters > 200 ||
                        (titleCharacters == 0 && errorBag.title),
                    }"
                    autofocus
                    required
                    :readonly="inReview"
                  />
                  <div>
                    <p class="tip">
                      <strong>Tip:</strong> The title is important, but remember
                      you can always change it later and this does not have to
                      be identical to your actual thesis title. Think of it as a
                      sales title.
                    </p>
                    <div class="charactor-counter">
                      Characters {{ titleCharacters }}/200
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="The subtitle"
                    v-model="collaborationDetails.subtitle"
                    :class="{
                      'limit-exceeded':
                        subTitleCharacters > 300 || errorBag.subtitle,
                    }"
                    required
                    :readonly="inReview"
                  />
                  <div class="charactor-counter">
                    Characters {{ subTitleCharacters }}/300
                  </div>
                </div>
                <div class="form-group">
                  <label>Content</label>
                  <!-- TinyMCE editor -->
                  <div
                    :class="{
                      'limit-exceeded':
                        mainContentCharacters > 5000 || errorBag.content,
                    }"
                  >
                    <tinymce
                      v-model="collaborationDetails.content"
                      key="1"
                      :disabled="inReview"
                    ></tinymce>
                  </div>
                  <div>
                    <p class="tip">
                      <strong>Tip: </strong>Remember, if you write too narrowly
                      on a subject you might limit yourself to a very small
                      group of companies if any. Writing too broad might make it
                      too vague. Finding the right balance is crucial.
                    </p>
                    <div class="charactor-counter">
                      Characters {{ mainContentCharacters }}/5000
                    </div>
                  </div>
                </div>
                <!-- /row -->
              </div>

              <!-- Value activeStep 3 -->
              <div v-if="activeStep == 2">
                <div
                  class="embed-responsive embed-responsive-21by9 col-12 col-md-8 mt-5 mb-5 mx-auto"
                >
                  <iframe
                    src="https://player.vimeo.com/video/600589746?h=d3f3da8165&color=ff0179&title=0&byline=0&portrait=0"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <form>
                  <div class="form-group">
                    <!-- <label>Value</label> -->
                    <!-- TinyMCE editor -->
                    <div
                      :class="{
                        'limit-exceeded': valueCharacters > 2000,
                      }"
                    >
                      <editor
                        api-key="m2w1lu787dyjizvo3s7jq88kx0d29932605cvce948xywugr"
                        v-model="collaborationDetails.value"
                        output-format="html"
                        :init="{
                          height: 300,
                          menubar: false,
                          branding: false,
                          plugins: ['lists', 'paste'],
                          paste_as_text: true,
                          statusbar: false,
                          toolbar:
                            'formatselect bold italic |  numlist bullist',
                        }"
                        key="4"
                        :disabled="inReview"
                      />
                    </div>
                    <div>
                      <p class="tip">
                        <strong>Tip: </strong>If the company can't see the value
                        in your thesis you will have a hard time selling it to
                        them. Make it glaringly obvious.
                      </p>
                      <div class="charactor-counter">
                        Characters {{ valueCharacters }}/2000
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <!-- Motivation activestep 4-->
              <div v-if="activeStep == 3">
                <div
                  class="embed-responsive embed-responsive-21by9 col-12 col-md-8 mt-5 mb-5 mx-auto"
                >
                  <iframe
                    src="https://player.vimeo.com/video/600589495?h=8275be1bf3&color=ff0179&title=0&byline=0&portrait=0"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <form>
                  <div class="form-group">
                    <!-- <label>Motivation</label> -->
                    <div
                      :class="{
                        'limit-exceeded': motivationCharacters > 2000,
                      }"
                    >
                      <tinymce
                        v-model="collaborationDetails.motivation"
                        key="5"
                        :disabled="inReview"
                      ></tinymce>
                    </div>
                    <div>
                      <p class="tip">
                        <strong>Tip: </strong>Throw some love into this one.
                        Make it obvious that you are passionate about the field.
                      </p>
                      <div class="charactor-counter">
                        Characters {{ motivationCharacters }}/2000
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <!--Why Us activestep 5-->
              <div v-if="activeStep == 4">
                <div
                  class="embed-responsive embed-responsive-21by9 col-12 col-md-8 mt-5 mb-5 mx-auto"
                >
                  <iframe
                    src="https://player.vimeo.com/video/600589781?h=7a456c7adb&color=ff0179&title=0&byline=0&portrait=0"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <form>
                  <div class="form-group">
                    <!-- <label>Why Us</label> -->
                    <div
                      :class="{
                        'limit-exceeded': whyusCharacters > 2000,
                      }"
                    >
                      <editor
                        api-key="m2w1lu787dyjizvo3s7jq88kx0d29932605cvce948xywugr"
                        v-model="collaborationDetails.whyus"
                        output-format="html"
                        :init="{
                          height: 300,
                          menubar: false,
                          body_class: 'form-control',
                          branding: false,
                          plugins: ['lists', 'paste'],
                          paste_as_text: true,
                          statusbar: false,
                          toolbar:
                            'formatselect bold italic |  numlist bullist',
                        }"
                        :disabled="inReview"
                        key="6"
                      />
                    </div>
                    <div>
                      <p class="tip">
                        <strong>Tip: </strong>Please put the 'Jante-lov' aside
                        and tell the world why you are the right pick here.
                      </p>
                      <div class="charactor-counter">
                        Characters {{ whyusCharacters }}/2000
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <!-- Preview -->
              <div v-if="activeStep == 5" class="review">
                <div
                  class="embed-responsive embed-responsive-21by9 col-12 col-md-8 mt-5 mb-5 mx-auto"
                  v-if="
                    collaborationDetails.status == 'draft' ||
                    collaborationDetails.status == 'approved'
                  "
                >
                  <iframe
                    src="https://player.vimeo.com/video/600589520?h=74c18155f1&color=ff0179&title=0&byline=0&portrait=0"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    v-if="collaborationDetails.status == 'draft'"
                  ></iframe>

                  <iframe
                    src="https://player.vimeo.com/video/600589554?h=e79ccacb73&color=ff0179&title=0&byline=0&portrait=0"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    v-if="collaborationDetails.status == 'approved'"
                  ></iframe>
                </div>
                <h5
                  class="pb-2"
                  :class="{
                    'mt-4':
                      collaborationDetails.status != 'draft' ||
                      collaborationDetails.status != 'approved',
                  }"
                >
                  <b>Title </b>
                  <span v-if="collaborationDetails.title">{{
                    collaborationDetails.title
                  }}</span>
                </h5>
                <h6 class="pb-2">
                  <b>Subtitle </b>
                  <span v-if="collaborationDetails.subtitle">{{
                    collaborationDetails.subtitle
                  }}</span>
                </h6>
                <p v-if="collaborationDetails.keywords">
                  <span
                    class="keyword"
                    v-for="(keyword, index) in collaborationDetails.keywords"
                    :key="index"
                    >{{ keyword }}</span
                  >
                </p>
                <div class="pb-2" v-if="collaborationDetails.content">
                  <strong>Thesis Description </strong>
                  <div class="para" v-html="collaborationDetails.content"></div>
                </div>
                <div class="pb-2" v-if="collaborationDetails.value">
                  <strong>Value </strong>
                  <div class="para" v-html="collaborationDetails.value"></div>
                </div>
                <div class="pb-2" v-if="collaborationDetails.motivation">
                  <strong>Motivation</strong>
                  <div
                    class="para"
                    v-html="collaborationDetails.motivation"
                  ></div>
                </div>
                <div class="pb-2" v-if="collaborationDetails.whyus">
                  <strong>Why Us</strong>
                  <div class="para" v-html="collaborationDetails.whyus"></div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <!-- Content column-->

              <div
                v-if="
                  activeStep == 1 &&
                  collaborationInvites &&
                  collaborationMembers
                "
              >
                <div class="form-group">
                  <div class="info-title mb-2" v-if="user.institution">
                    <strong>University: </strong> {{ user.institution.name }}
                  </div>
                  <div class="info-title mb-2" v-show="user.faculty">
                    <strong>Faculty: </strong> {{ user.faculty }}
                  </div>
                  <div class="info-title mb-2">
                    <strong>Degree: </strong> {{ user.area }}
                  </div>

                  <label>Collaboration semester</label>
                  <select
                    class="form-control mb-4"
                    v-model="collaborationDetails.targetSemester"
                    @change="handleDates"
                    required
                    :disabled="inReview"
                  >
                    <option
                      v-for="(semester, index) in semesters"
                      :key="index"
                      :value="semester.value"
                    >
                      {{ semester.value }}
                    </option>
                  </select>

                  <div class="form-group">
                    <label for>What languages do you speak?</label>
                    <div>
                      <div
                        class="form-check form-check-inline"
                        v-for="(language, index) in languages"
                        :key="index"
                      >
                        <label class="control control-checkbox" :for="language">
                          {{ language }}
                          <input
                            type="checkbox"
                            :id="language"
                            :value="language"
                            v-model="collaborationDetails.languages"
                            :disabled="inReview"
                          />
                          <div class="control_indicator"></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="mb-2">
                    <label for="first-startdate"
                      ><span class="color-first">First</span> possible
                      collaboration start date</label
                    >
                    <input
                      type="date"
                      placeholder="enter date"
                      class="form-control"
                      id="first-startdate"
                      name="startdate-first"
                      v-model="collaborationDetails.firstStartDate"
                      :disabled="inReview"
                      required
                    />
                  </div>
                  <div class="mb-2">
                    <label for="last-startdate"
                      ><span class="color-first">Last</span> possible
                      collaboration start date</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      id="last-startdate"
                      value="startdate-last"
                      v-model="collaborationDetails.lastStartDate"
                      :disabled="inReview"
                      required
                    />
                  </div>
                  <div class="mb-2">
                    <label for="hand-in-date">Thesis hand-in date</label>
                    <input
                      type="date"
                      class="form-control"
                      id="hand-in-date"
                      value="thesisHandInDate"
                      v-model="collaborationDetails.thesisHandInDate"
                      :disabled="inReview"
                      required
                    />
                  </div>
                </div>
                <!--  error-->
                <div class="alert alert-danger mt-2" v-if="inviteError">
                  {{ inviteError }}
                </div>
                <!-- end -->
                <div class="form-group">
                  <button
                    @click="activeStep++"
                    class="btn btn-primary btn-lg btn-block"
                    v-if="inReview"
                  >
                    Save and next
                  </button>

                  <button
                    @click="nextStep(2, 'Content')"
                    class="btn btn-primary btn-lg btn-block"
                    v-else
                  >
                    Save and next
                  </button>
                </div>
              </div>

              <!-- Value   -->

              <div v-if="activeStep == 2">
                <div class="form-group" style="margin-top: 30px">
                  <h4 class="mb-2">
                    <strong>Value</strong>
                  </h4>
                  <div>
                    <p></p>
                    <p>Now we are talking business.</p>

                    <p>
                      What value would a collaboration with you bring to a
                      company - maybe not financially, but is it innovation? Is
                      activeStep it research? New insights? Or something
                      completely different?
                    </p>
                    <p>Why is this worth the companies time?</p>
                    <p>
                      The focus is on your thesis and not on you as individuals
                    </p>

                    <b style="color: #555">Here is an example</b>
                    <p>
                      Our hypothesis, if proven, could potentially have a great
                      impact on the industry. [Remember to also explain why
                      and/or how your hypothesis/findings could have a great
                      impact on the industry].
                      <br />
                      The knowledge and insights our thesis provides will be
                      available to you, since you will be our thesis-partner,
                      before any other players in your field/industry. We
                      believe that this can be very valuable and of potentially
                      give you a great competitive advantage.
                    </p>
                  </div>
                  <div class="form-group">
                    <button
                      @click="activeStep++"
                      class="btn btn-primary btn-lg btn-block"
                      v-if="inReview"
                    >
                      Save and next
                    </button>
                    <button
                      v-else
                      @click="nextStep(4, 'Value')"
                      class="btn btn-primary btn-lg btn-block"
                    >
                      Save and next
                    </button>
                  </div>
                </div>
              </div>

              <!-- Motivation -->

              <div v-if="activeStep == 3">
                <div class="form-group" style="margin-top: 30px">
                  <h4 class="mb-2">
                    <strong>Motivation</strong>
                  </h4>
                  <div>
                    <p>
                      Motivation can be many things but tell us as a group, why
                      is this specific topic interesting to you?
                    </p>
                    <p>
                      Your area of study can take you in so many different
                      directions, but why did you choose this one?
                    </p>
                    <b style="color: #555">Here is an example</b>
                    <p>
                      We all fell in love with the theme during our 5-semester
                      course and two of us have internships in the same
                      industry. This is what we to work with going forward. It
                      is an industry that is ever-evolving, which is what makes
                      it super exciting and we get to work with the latest
                      research and findings which is very motivating.
                    </p>
                  </div>

                  <div class="form-group">
                    <button
                      @click="activeStep++"
                      class="btn btn-primary btn-lg btn-block"
                      v-if="inReview"
                    >
                      Save and next
                    </button>
                    <button
                      v-else
                      @click="nextStep(5, 'Motivation')"
                      class="btn btn-primary btn-lg btn-block"
                    >
                      Save and next
                    </button>
                  </div>
                </div>
              </div>

              <!-- Why us -->

              <div v-if="activeStep == 4">
                <div class="form-group" style="margin-top: 30px">
                  <h4 class="mb-2">
                    <strong>Why Us</strong>
                  </h4>
                  <div>
                    <p>
                      This is the big one. Out of all the other students who are
                      writing within the same area of study, why should the
                      company pick you?
                    </p>
                    <p>
                      The focus is on you and not on your thesis. What do you
                      bring to the table that the companies cannot say no to?
                    </p>
                    <p>
                      Is it the unique composition of your thesis team? or is it
                      your extraordinary knowledge on the subject? or is it your
                      die-hard grit? or is it something else?
                    </p>
                    <b>Here is an example</b>
                    <p>
                      We all wrote our Bachelor thesis together, with a company
                      relevant within the same industry, with great grades to
                      back that up, and we enjoyed every moment of it.
                    </p>
                    <p>
                      We have had 3 courses within the field and we are
                      convinced that this is what we want to work with after we
                      graduate. We would like to finish strong and knock this
                      thesis out of the park.
                    </p>
                  </div>

                  <div class="form-group">
                    <button
                      @click="activeStep++"
                      class="btn btn-primary btn-lg btn-block"
                      v-if="inReview"
                    >
                      Save and next
                    </button>
                    <button
                      v-else
                      @click="nextStep(6, 'Why us')"
                      class="btn btn-primary btn-lg btn-block"
                    >
                      Save and next
                    </button>
                  </div>
                </div>
              </div>

              <!-- Preview -->

              <div class="preview" v-if="activeStep == 5">
                <div class="form-group" style="">
                  <h4><strong>Preview</strong></h4>

                  <div class="my-2" v-if="user.institution">
                    <strong>University</strong>
                    <p>{{ user.institution.name }}</p>
                  </div>
                  <div class="my-2">
                    <strong>Degree </strong>
                    <p>{{ user.degree }}</p>
                  </div>
                  <div class="my-2">
                    <strong>Faculty</strong>
                    <p>{{ user.faculty }}</p>
                  </div>
                  <div class="my-2">
                    <strong>Area </strong>
                    <p>{{ user.area }}</p>
                  </div>
                  <div class="my-2" v-if="collaborationDetails">
                    <strong>Thesis semester</strong>
                    <p>{{ collaborationDetails.targetSemester }}</p>
                  </div>
                  <div class="my-2">
                    <strong>First possible collaboration start date</strong>
                    <p>{{ collaborationDetails.firstStartDate }}</p>
                  </div>
                  <div class="my-2">
                    <strong>Last possible collaboration start date</strong>
                    <p>{{ collaborationDetails.lastStartDate }}</p>
                  </div>
                  <div class="my-2">
                    <strong>Thesis hand-in-date</strong>
                    <p>{{ collaborationDetails.thesisHandInDate }}</p>
                  </div>
                  <div class="my-2">
                    <strong>Minimum collaboration time</strong>
                    <p>
                      {{
                        diff_weeks(
                          collaborationDetails.lastStartDate,
                          collaborationDetails.thesisHandInDate
                        )
                      }}
                      weeks
                    </p>
                  </div>
                  <div class="my-2">
                    <strong>Thesis group speaks</strong>
                    <p>
                      {{
                        collaborationDetails.languages.join().replace(',', ', ')
                      }}
                    </p>
                  </div>
                  <div
                    class="invite-area"
                    v-if="
                      !isInvited ||
                      (firstTimeInvite &&
                        collaborationDetails.numberOfStudents == 1)
                    "
                  >
                    <h6>Team members</h6>
                    <p>
                      How many students are in the thesis group? (including you)
                    </p>
                    <select
                      class="form-control mb-4"
                      v-model="collaborationDetails.numberOfStudents"
                      @change="handleNumberOfInvites"
                      aria-label="Default select example"
                      :disabled="inReview"
                    >
                      <option
                        v-for="(item, index) in numberOfStudents"
                        :value="item.value"
                        :key="index"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="invite-member"
                    v-if="
                      !isInvited &&
                      collaborationDetails.numberOfStudents > 1 &&
                      firstTimeInvite
                    "
                  >
                    <label>Invite your student group members</label>
                    <form @submit.prevent="sendInvites">
                      <input
                        v-for="(number, index) in parseInt(numberOfInvites)"
                        :key="index"
                        type="email"
                        :placeholder="
                          'Enter email for member#' + (number + 1) + ''
                        "
                        v-model="
                          collaborationDetails.studentEmails['student' + number]
                        "
                        class="form-control mb-2"
                        :disabled="inReview"
                        required
                      />
                      <p class="small-text">
                        Student group members will receive a mail with a link to
                        join the group.
                      </p>
                      <div class="alert alert-danger" v-if="inviteError">
                        {{ inviteError }}
                      </div>
                      <button
                        type="submit"
                        class="btn btn-primary btn-sm mb-4"
                        style="font-size: 14px padding: 0.285rem 1.2rem"
                        :disabled="numberOfInvites < 1"
                      >
                        Send invitation
                      </button>
                    </form>
                  </div>
                  <!-- @click="sendInvites" -->

                  <div class="my-2">
                    <strong>Keywords</strong>
                    <p>
                      Describe your thesis topic / research area with relevant
                      keywords. We use the keywords to match you with the right
                      companies. Type in up to 5 keywords.
                    </p>
                    <input
                      v-for="(number, index) in 5"
                      :key="index"
                      type="text"
                      :placeholder="'keyword#' + number + ''"
                      v-model="collaborationDetails.keywords[index]"
                      class="form-control mb-2"
                      :disabled="inReview"
                    />
                  </div>
                  <div class="form-group">
                    <!-- modal -->
                    <!-- Button trigger modal  @click="saveCollaboration()"-->
                    <!-- When Collaboration status is Draft -->

                    <div v-if="collaborationDetails.status == 'draft'">
                      <p v-if="!isSubmitAllowed">
                        <i class="fas fa-exclamation-circle color-red"></i>
                        Check that all required fields are filled out on all
                        steps
                      </p>
                      <button
                        type="button"
                        class="btn btn-primary btn-block mb-4 mt-4"
                        data-toggle="modal"
                        data-target="#submitReview"
                        @click="preSubmit"
                        :disabled="
                          (!isInvited && numberOfInvites > 0) ||
                          (!isSubmitAllowed &&
                            collaborationDetails.numberOfStudents <= 1) ||
                          (!isSubmitAllowed && numberOfInvites > 0)
                        "
                      >
                        Submit for review
                      </button>
                      <p class="small-text">
                        Please allow the Excelerate team 48 hours for the
                        Review.
                        <br />
                        You will not be able to edit the thesis topic while we
                        are reviewing it, but you will receive an email once the
                        review is complete.
                      </p>
                    </div>
                    <div v-if="collaborationDetails.status == 'review'">
                      <button
                        type="button"
                        class="btn btn-primary btn-block mb-4 mt-4 disabled"
                        disabled
                      >
                        In Review
                      </button>
                      <p class="small-text">
                        Please allow the Excelerate team 48 hours for the
                        interview.
                        <br />
                        You will not be able to edit the thesis topic while we
                        are reviewing.
                      </p>
                    </div>
                    <!-- Approved but invitation still pending -->
                    <div
                      v-if="
                        collaborationDetails.status == 'approved' &&
                        collaborationInvites.length != 0
                      "
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-block mb-4 mt-4 disabled"
                        disabled
                      >
                        Publish
                      </button>
                      <p class="small-text">
                        <b
                          >Make sure all thesis group members has sign up to the
                          Excelerate platform and has accepted your
                          invitation</b
                        >
                      </p>
                    </div>
                    <!-- Approved and all accepted invitation-->
                    <div
                      v-if="
                        collaborationDetails.status == 'approved' &&
                        collaborationInvites.length == 0
                      "
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-block mb-4 mt-4"
                        @click="changeStatus('published')"
                      >
                        Publish
                      </button>
                      <p>
                        <b
                          >Your thesis topic will be visible to companies when
                          you publish</b
                        >
                      </p>
                    </div>
                    <!-- <div v-if="collaborationDetails.status == 'draft'"></div> -->

                    <!-- Modal -->

                    <div
                      class="modal fade"
                      id="submitReview"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="submitReviewTitle"
                      aria-hidden="true"
                      v-if="isModalOpen"
                    >
                      <div
                        class="modal-dialog modal-lg modal-dialog-centered modal-md"
                        role="document"
                      >
                        <div class="modal-content modal-box">
                          <div class="splash-line"></div>

                          <div class="modal-body">
                            <button
                              id="closeModal"
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              @click="isModalOpen = !isModalOpen"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <div class="modal-body mx-auto">
                              <div class="container-fluid text-center">
                                <h5
                                  class="modal-title py-3"
                                  id="submitReviewTitle"
                                >
                                  Please confirm submitting your thesis topic
                                  for review
                                </h5>
                                <p>
                                  We do not edit your content or have an opinion
                                  on the subject you have chosen. The review is
                                  just to verify that the data quality is of a
                                  high enough standard and if we spot any low
                                  hanging fruits or obvious mistakes then we
                                  will of course let you know.
                                </p>
                                <p>
                                  Please allow the Excelerate team 48 hours for
                                  the Review.
                                  <br />
                                  You will not be able to edit the thesis topic
                                  while we are reviewing it, but you will
                                  receive an email once the review is complete.
                                </p>
                                <p>
                                  If approved, we will publish your thesis for
                                  you.
                                </p>

                                <div style="text-align: center">
                                  <button
                                    class="btn btn-primary"
                                    @click="dispatchCollaborationThesis()"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- row end -->

        <!-- 2nd column -->
      </div>
      <div class="col-12 col-md-9 col-lg-9 main-body p-0">
        <div class="widget mb-3" v-if="activeStep == 5">
          <div class="widget-header sticky">
            <h4 id="members">
              Members
              <small
                v-if="
                  collaborationMembers.length -
                    1 +
                    collaborationInvites.length >
                  0
                "
              >
                ({{ collaborationMembers.length - 1 }}/{{
                  collaborationMembers.length - 1 + collaborationInvites.length
                }}
                invites accepted)</small
              >
            </h4>
            <div class="widget-navigation">
              <span
                class="widget-nav"
                data-toggle="modal"
                data-target="#collabInvite"
                v-if="
                  isUserOwner &&
                  isEditable &&
                  (collaborationDetails.status == 'draft' ||
                    collaborationDetails.status == 'approved')
                "
                ><i class="fas fa-paper-plane"></i> Invite team member</span
              >
            </div>
          </div>
          <div class="widget-body">
            <div v-if="collaborationInvites.length > 0">
              <!-- invites -->
              <UsersInvites
                :collaborationInvites="collaborationInvites"
                :user="user"
                :isUserOwner="isUserOwner"
              ></UsersInvites>
            </div>
            <!-- members -->
            <Member
              :profile="member.members"
              :id="member.id"
              :isUserOwner="isUserOwner"
              :user="user"
              :isUserOfferingViewingAproved="isUserOfferingViewingAproved"
              :remove="true"
              v-for="(member, index) in collaborationMembers"
              :key="index"
              :index="index"
              @removeMember="rmMember"
            ></Member>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="collabInvite"
      tabindex="-1"
      role="dialog"
      aria-labelledby="collabInvite"
      aria-hidden="true"
      v-if="isUserOwner"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="collabInvite">Invite team member</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="sendInvitationOnCollaboration()">
              <div class="form-row">
                <div class="col-12 col-lg-8">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter email to invite"
                    v-model="inviteEmail"
                    required
                  />
                </div>
                <div class="col-12 col-lg-4">
                  <button class="btn btn-primary btn-block">Invite</button>
                </div>
              </div>
              <div class="alert alert-danger mt-2" v-if="inviteError">
                {{ inviteError }}
              </div>
              <div class="alert alert-success mt-2" v-if="inviteMessage">
                {{ inviteMessage }}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MasterHeader from '@/components/MasterHeader.vue';
import { db } from '../../firebase';
import json_semesters from '@/json/semesters.json';
import Editor from '@tinymce/tinymce-vue';
import tinymce from '@/components/Editor.vue';
import Loader from '@/components/Loader.vue';
import Member from '@/components/Member.vue';
import UsersInvites from '@/components/UsersInvites.vue';
import SignUpModal from '../../components/signUpModal.vue';
// import moment from ""

// import { mapState } from 'vuex'
export default {
  data() {
    return {
      // invitations
      inviteEmail: '',
      inviteError: '',
      inviteMessage: '',
      isNewCollaboration:
        typeof this.$route.query.new != 'undefined' ? true : false,
      openThroughTab:
        typeof this.$route.query.tab != 'undefined' ? true : false,
      isPreviewTab:
        typeof this.$route.query.preview != 'undefined' ? true : false,
      collaborationDetails: {
        isNew: false,
        title: '',
        subtitle: '',
        content: '',
        value: '',
        motivation: '',
        whyus: '',
        pitch: '',
        languages: [],
        numberOfStudents: 0,
        members: [],
        firstStartDate: '',
        lastStartDate: '',
        thesisHandInDate: '',
        studentEmails: {},
        targetSemester: this.$store.state.user.graduatingSemester,
        stepCompleted: 0,
        keywords: [],
        stepsStatus: {},
      },
      collaboration: {},
      isModalOpen: false,

      // titleCharacters: 0,
      // subTitleCharacters: 0,
      // mainContentCharacters: 0,
      // isTitleCharactersLimitExceeded: false,
      // isSubTitleCharactersLimitExceeded: false,
      // isMainContentCharactersLimitExceeded: false,
      // methodologyCharacters:0,
      isUserOwner: false,
      // newCollabId:null,
      steps: [
        // "",
        'Content',
        'Value',
        'Motivation',
        'Why us',
        'Preview',
      ],
      activeStep: 1,
      languages: [
        'Danish',
        'Swedish',
        'Norwegian',
        'English',
        'German',
        'Spanish',
        'Other',
      ],
      numberOfInvites: 0,
      firstTimeInvite: false,
      numberOfStudents: [
        { name: 'Select', value: '0' },
        { name: '1 (just me)', value: '1' },
        { name: '2', value: '2' },
        { name: '3', value: '3' },
        { name: '4', value: '4' },
        { name: '5', value: '5' },
        { name: '6', value: '6' },
        { name: '7', value: '7' },
        { name: '8', value: '8' },
        { name: '9', value: '9' },
        { name: '10', value: '10' },
      ],
      semesters: json_semesters,
      errorBag: [
        { title: false },
        { subtitle: false },
        { content: false },
        { value: false },
        { whyus: false },
        { motivation: false },
        { submit: true },
      ],
    };
  },
  components: {
    MasterHeader,
    editor: Editor,
    tinymce,
    Loader,
    Member,
    UsersInvites,
    SignUpModal,
  },
  firestore() {
    return {
      collaboration: db.collection('collaborations').doc(this.$route.params.id),
    };
  },
  watch: {
    collaborationDetails: function (val, newVal) {
      if (this.isNewCollaboration) {
        this.activeStep = val.stepCompleted + 1;
      }
      if (this.isPreviewTab) {
        this.activeStep = 6;
      }
      if (this.activeStep == '1' || this.activeStep == '2') {
        if (typeof val.studentEmails == 'undefined') {
          this.collaborationDetails.studentEmails = {};
        }
        // if (
        //   typeof val.numberOfStudents == "undefined" ||
        //   val.numberOfStudents == ""
        // ) {
        //   this.collaborationDetails.numberOfStudents = 1;
        // }
      }
      // if (typeof this.collaborationMembers == typeof undefined || this.activeStep == 6) {
      //     this.isUserOwner = this.isUserOwnerCom;
      // }
    },
    activeStep: function (val) {
      // console.log(val, this.$router.query);
      // this.$router.push({query:Object.assign(this.$route.query, {'tab':this.steps[val-1].title})});
      // this.$router.query.tab = this.steps[val-1].title;
      // this.$router.push({
      //   path: this.$route.path,
      //   query: { ...this.$route.query, tab: this.steps[val - 1].title },
      // });
      this.getCollaborationMetas();
      this.isUserOwner = this.isUserOwnerCom;
      // if (
      //   typeof this.collaborationMembers == typeof undefined) {
      //   console.log("change:activeState");

      // }
    },
  },
  computed: {
    ...mapState(['user']),
        hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
    isLoaded: function () {
      let res = false;
      var vm = this;
      if (typeof vm.collaboration.title != 'undefined') {
        vm.collaborationDetails = Object.assign(
          {},
          vm.collaborationDetails,
          this.collaboration
        );
        console.log('ok');
        vm.isUserOwner = this.isUserOwnerCom;
        vm.collaboration = vm.collaborationDetails;
        // vm.collaborationDetails = Object.extend(){...vm.collaborationDetails,...this.collaboration};
        res = true;
      }

      return res;
    },
    isEditable: function () {
      var result = this.collaborationOffers;
      result = result.filter((res) => res.offer.status > 0);
      return result.length > 0 ? false : true;
    },
    isUserOwnerCom: function () {
      //  console.log(this.collaboration.stepCompleted)
      console.log('trigger:isUserOwnerCom()');
      var result = false;
      var members = this.collaboration.members;
      if (typeof members !== typeof undefined) {
        result = members.includes(this.user.userId);
      }
      return result;
    },
    inReview: function () {
      return this.collaborationDetails.status == 'review' ||
        this.collaborationDetails.status == 'published'
        ? true
        : false;
    },
    collaborationOffers: function () {
      return this.$store.state.collaboration.collaborationOffers;
    },
    isUserOfferingViewingAproved: function () {
      var result = false;
      var userCompanyId = this.user.company;
      var companyOffers = this.collaborationOffers;
      //is users companyId matching any of the offering companies' id and is the user a pro role.
      if (
        typeof undefined !== typeof companyOffers &&
        this.user.role === 'Professional'
      ) {
        companyOffers.forEach(function (offer, index) {
          result =
            offer.offer.companyId === userCompanyId && offer.offer.status >= 3
              ? true
              : false;
        });
      }
      return result;
    },
    collaborationMembers: function () {
      return this.$store.state.collaboration.collaborationMembers;
    },
    collaborationInvites: function () {
      return this.$store.state.collaboration.collaborationInvites;
    },
    isInvited: function () {
      let res = false;
      if (
        this.collaborationMembers.length != 'undefined' &&
        this.collaborationInvites.length != 'undefined'
      ) {
        if (this.collaborationDetails.numberOfStudents == '1') {
          res = 1;
        } else {
          let invitesAndMembersCount =
            this.collaborationInvites.length + this.collaborationMembers.length;
          res = invitesAndMembersCount > 1 ? true : false;
        }
      }
      return res;
    },
    // methodologyCharactors: function() {
    //   var res = 0;
    //   if (typeof this.collaborationDetails.methodology != "undefined") {
    //     res = this.htmlToText(this.collaborationDetails.methodology).length;
    //   }
    //   return res;
    // },
    valueCharacters: function () {
      var res = 0;
      if (typeof this.collaborationDetails.value != 'undefined') {
        res = this.htmlToText(this.collaborationDetails.value).length;
      }
      return res;
    },
    motivationCharacters: function () {
      var res = 0;
      if (typeof this.collaborationDetails.motivation != 'undefined') {
        res = this.htmlToText(this.collaborationDetails.motivation).length;
      }
      return res;
    },
    whyusCharacters: function () {
      var res = 0;
      if (typeof this.collaborationDetails.whyus != 'undefined') {
        res = this.htmlToText(this.collaborationDetails.whyus).length;
      }
      return res;
    },
    titleCharacters: function () {
      var res = 0;
      if (typeof this.collaborationDetails.title != 'undefined') {
        res = this.collaborationDetails.title.length;
      }
      return res;
    },
    subTitleCharacters: function () {
      var res = 0;
      if (typeof this.collaborationDetails.subtitle != 'undefined') {
        res = this.collaborationDetails.subtitle.length;
      }
      return res;
    },
    mainContentCharacters: function () {
      var res = 0;
      if (typeof this.collaborationDetails.content != 'undefined') {
        res = this.htmlToText(this.collaborationDetails.content).length;
      }
      return res;
    },
    isSubmitAllowed: function () {
      let res = false;
      let step = this.activeStep;
      res = this.submitTest(step);
      return res;
    },
  },

  methods: {
    getCollaborationMetas: function () {
      //  console.log(this.collaboration.stepCompleted)
      var result = false;
      var members = this.collaborationDetails.members;
      if (typeof members !== typeof undefined) {
        // console.log("triggered:isUserOwnersd()");
        this.$store.dispatch(
          'getCollaborationMembers',
          this.collaborationDetails.members
        );
        this.$store.dispatch(
          'getCollaborationInvitesByCollabId',
          this.$route.params.id
        );
        this.$store.dispatch(
          'getCollaborationOffersCollabId',
          this.$route.params.id
        );
      }
      return result;
    },
    handleDates: function () {
      // if(this.collaborationDetails.firstStartDate != '')
      let semester = this.collaborationDetails.targetSemester;
      if (semester == 'Fall 2021') {
        this.collaborationDetails.firstStartDate =
          this.collaborationDetails.firstStartDate || '2021-09-01';
        this.collaborationDetails.lastStartDate =
          this.collaborationDetails.lastStartDate || '2021-11-01';
        this.collaborationDetails.thesisHandInDate =
          this.collaborationDetails.thesisHandInDate || '2021-12-15';
      } else if (semester == 'Spring 2022') {
        this.collaborationDetails.firstStartDate =
          this.collaborationDetails.firstStartDate || '2022-01-01';
        this.collaborationDetails.lastStartDate =
          this.collaborationDetails.lastStartDate || '2022-03-01';
        this.collaborationDetails.thesisHandInDate =
          this.collaborationDetails.thesisHandInDate || '2022-05-15';
      } else if (semester == 'Fall 2022') {
        this.collaborationDetails.firstStartDate =
          this.collaborationDetails.firstStartDate || '2022-09-01';
        this.collaborationDetails.lastStartDate =
          this.collaborationDetails.lastStartDate || '2022-11-01';
        this.collaborationDetails.thesisHandInDate =
          this.collaborationDetails.thesisHandInDate || '2022-12-15';
      } else if (semester == 'Spring 2023') {
        this.collaborationDetails.firstStartDate =
          this.collaborationDetails.firstStartDate || '2023-01-01';
        this.collaborationDetails.lastStartDate =
          this.collaborationDetails.lastStartDate || '2023-03-01';
        this.collaborationDetails.thesisHandInDate =
          this.collaborationDetails.thesisHandInDate || '2023-05-15';
      } else if (semester == 'Fall 2023') {
        this.collaborationDetails.firstStartDate =
          this.collaborationDetails.firstStartDate || '2023-09-01';
        this.collaborationDetails.lastStartDate =
          this.collaborationDetails.lastStartDate || '2023-11-01';
        this.collaborationDetails.thesisHandInDate =
          this.collaborationDetails.thesisHandInDate || '2023-12-15';
      } else if (semester == 'Spring 2024') {
        this.collaborationDetails.firstStartDate =
          this.collaborationDetails.firstStartDate || '2024-01-01';
        this.collaborationDetails.lastStartDate =
          this.collaborationDetails.lastStartDate || '2024-03-01';
        this.collaborationDetails.thesisHandInDate =
          this.collaborationDetails.thesisHandInDate || '2024-05-15';
      } else if (semester == 'Fall 2024') {
        this.collaborationDetails.firstStartDate =
          this.collaborationDetails.firstStartDate || '2024-09-01';
        this.collaborationDetails.lastStartDate =
          this.collaborationDetails.lastStartDate || '2024-11-01';
        this.collaborationDetails.thesisHandInDate =
          this.collaborationDetails.thesisHandInDate || '2024-12-15';
      } else if (semester == 'Spring 2025') {
        this.collaborationDetails.firstStartDate =
          this.collaborationDetails.firstStartDate || '2025-01-01';
        this.collaborationDetails.lastStartDate =
          this.collaborationDetails.lastStartDate || '2025-03-01';
        this.collaborationDetails.thesisHandInDate =
          this.collaborationDetails.thesisHandInDate || '2025-05-15';
      } else if (semester == 'Fall 2025') {
        this.collaborationDetails.firstStartDate =
          this.collaborationDetails.firstStartDate || '2025-09-01';
        this.collaborationDetails.lastStartDate =
          this.collaborationDetails.lastStartDate || '2025-11-01';
        this.collaborationDetails.thesisHandInDate =
          this.collaborationDetails.thesisHandInDate || '2025-12-15';
      }
    },
    tabNavigation: function (index) {
      // if (index + 1 < this.activeStep) {
      this.activeStep = index + 1;
      // } else if (this.submitTest(index) && this.isSubmitAllowed) {
      // this.activeStep = index + 1;
      // this.nextStep(index + 1);
      // }
    },
    submitTest: function (step) {
      // debugger;
      let res = false;
      switch (step) {
        case 1:
          if (
            this.titleCharacters == 0 ||
            this.subTitleCharacters == 0 ||
            this.mainContentCharacters == 0 ||
            this.collaborationDetails.languages.length == 0 ||
            this.collaborationDetails.firstStartDate == '' ||
            this.collaborationDetails.lastStartDate == '' ||
            this.collaborationDetails.thesisHandInDate == '' ||
            this.collaborationDetails.targetSemester == '' ||
            this.titleCharacters > 200 ||
            this.subTitleCharacters > 300 ||
            this.mainContentCharacters > 5000
          ) {
            res = false;
          } else {
            res = true;
          }
          break;
        case 2:
          if (this.valueCharacters == 0 || this.valueCharacters > 2000) {
            res = false;
          } else {
            res = true;
          }
          break;
        // case 3:
        //   if (this.valueCharacters == 0 || this.valueCharacters > 2000) {
        //     res = false;
        //   } else {
        //     res = true;
        //   }
        //   break;
        case 3:
          if (
            this.motivationCharacters == 0 ||
            this.motivationCharacters > 2000
          ) {
            res = false;
          } else {
            res = true;
          }
          break;
        case 4:
          if (this.whyusCharacters == 0 || this.whyusCharacters > 2000) {
            res = false;
          } else {
            res = true;
          }
          break;
        case 5:
          if (
            this.collaborationDetails.stepsStatus['Value'] === 1 &&
            this.collaborationDetails.stepsStatus['Content'] === 1 &&
            this.collaborationDetails.stepsStatus['Motivation'] === 1 &&
            this.collaborationDetails.stepsStatus['Why us'] === 1
          ) {
            res = true;
          } else {
            res = false;
          }
          break;
        // case 6:

        //   if (
        //     this.collaborationDetails.keywords.includes("") ||
        //     this.collaborationDetails.keywords.includes(undefined) ||
        //     this.collaborationDetails.keywords.length != 5 ||
        //     !parseInt(this.collaborationDetails.numberOfStudents)
        //   ) {
        //   //   // console.log(this.collaborationDetails.keywords.includes(""),
        //   //   // this.collaborationDetails.keywords.includes(undefined),
        //   //   // this.collaborationDetails.keywords.length != 5,
        //   //   // this.collaborationDetails.numberOfStudents === '',this.collaborationDetails.numberOfStudents)
        //     res = false;
        //   } else {
        //     res = true;
        //   }
        //   break;
        default:
          break;
      }
      return res;
    },
    handleNumberOfInvites: function () {
      this.numberOfInvites =
        this.collaborationDetails.numberOfStudents == '' ||
        this.collaborationDetails.numberOfStudents == 0
          ? 0
          : parseInt(this.collaborationDetails.numberOfStudents) - 1;
      if (this.collaborationDetails.numberOfStudents == '1') {
        this.firstTimeInvite = true;
      }
    },
    rmMember(member) {
      var upmc = this.collaborationDetails.members.filter((cm) => cm != member);
      this.$store.dispatch('updateCollaborationMembers', {
        collaborationId: this.$route.params.id,
        members: upmc,
      });
      this.$store.dispatch(
        'getCollaborationMembers',
        this.collaborationDetails.members
      );
      // var updatedCollaborationMember = this.collaborationMembers.map(cm=>cm.id);
    },
    async nextStep(step, type) {
      let status;
      if (this.isSubmitAllowed) {
        status = 1;
      } else {
        status = 0;
      }
      if (this.isNewCollaboration) {
        this.collaborationDetails.stepCompleted = this.activeStep;
      }
      // this.mainContentCharacters = 0;
      this.activeStep = step;
      const collaborationDetails = {
        ...this.collaborationDetails,
        stepsStatus: {
          ...this.collaborationDetails.stepsStatus,
          [type]: status,
        },
      };
      const data = {
        id: this.$route.params.id,
        collaborationDetails,
        // collaborationDetails: this.collaborationDetails,
        user: this.user,
      };
      this.$store.dispatch('updateCollaborationDetail', data);
      // }
    },
    async validateUniqueMembers(emails) {
      var vm = this;
      let isAlreadyInvited = false;
      emails.forEach((email) => {
        vm.collaborationMembers.forEach((member) => {
          // console.log("collaboration Members", member.members.email, email);
          if (member.members.email == email) {
            isAlreadyInvited = true;
          }
        });
      });
      return isAlreadyInvited;
    },
    async validateUniqueInvites(emails) {
      var vm = this;
      let isAlreadyInvited = false;
      emails.forEach((email) => {
        vm.collaborationInvites.forEach((member) => {
          // console.log(
          //   "collaboration Members",
          //   member.invites.targetEmail,
          //   email
          // );
          if (member.invites.targetEmail == email) {
            isAlreadyInvited = true;
          }
        });
      });
      return isAlreadyInvited;
    },
    async sendInvites() {
      var vm = this;
      var d3 = false;
      vm.inviteError = '';
      vm.inviteMessage = '';
      const studentEmails = Object.values(
        vm.collaborationDetails.studentEmails
      );

      let [isAlreadyMember, isAlreadyInvited] = await Promise.all([
        this.validateUniqueMembers(studentEmails),
        this.validateUniqueInvites(studentEmails),
      ]);
      if (isAlreadyInvited || isAlreadyMember) {
        this.inviteError = 'User is already a member or invited';
        return false;
      }
      // var pendingCollaborationInvites = this.collaborationInvites;
      // var existingMembers = this.collaborationMembers;

      var members = [];
      studentEmails.forEach(async (studentEmail) => {
        db.collection('users')
          .where('email', '==', studentEmail)
          .get()
          .then(function (querySnapshot) {
            var collection = '';
            querySnapshot.forEach((doc) => {
              collection = { id: doc.id, user: doc.data() };
              members.push(doc.id);
            });

            const title = vm.collaborationDetails.title;
            var data = {
              collaborationId: vm.$route.params.id,
              collaborationTitle: title,
              sender: vm.user.firstName + ' ' + vm.user.lastName,
              senderEmail: vm.user.email,
              targetEmail: studentEmail,
            };

            if (collection != '') {
              // console.log("user already exists")
              //if user already exists
              if (collection.user.role === 'Student') {
                //MAIL 'Student - Invitation Recieved, already a user' @firstName, lastName, param1: firstName (receiver), param2: collaboration.title, param3: collaboration.id
                const templateData = {
                  toEmail: studentEmail,
                  firstName: collection.user.firstName,
                  param1: vm.user.firstName,
                  param2: vm.user.lastName,
                  param3: title,
                  param4: vm.$route.params.id,
                  template: 'Student - Invitation Recieved, already a user',
                };
                vm.$store.dispatch('sendMail', templateData);
                vm.$store.dispatch('inviteUserToCollabortion', data);
                vm.$store.dispatch(
                  'getCollaborationInvitesByCollabId',
                  vm.$route.params.id
                );
                // vm.$store.dispatch('getCollaborationInvitesByCollabId', vm.$route.params.id);

                vm.inviteMessage = 'Invite send.';
                vm.inviteError = false;
              } else {
                vm.inviteError = 'The user you are inviting is not a student'; //not sending or dispatching anything
              }
            } else {
              // user does not exist
              //MAIL 'Student - Invitation Recieved, not a user' @firstName (this user), lastName (this user), param1: collaboration.title
              const templateData = {
                toEmail: studentEmail,
                firstName: vm.user.firstName,
                lastName: vm.user.lastName,
                param1: vm.collaborationDetails.title,
                template: 'Student - Invitation Recieved, not a user',
              };
              vm.$store.dispatch('sendMail', templateData);
              vm.$store.dispatch('inviteUserToCollabortion', data);
              vm.$store.dispatch(
                'getCollaborationInvitesByCollabId',
                vm.$route.params.id
              );
              //  vm.$store.dispatch('getCollaborationInvitesByCollabId', vm.$route.params.id);
              vm.inviteMessage = 'Invite send.';
              vm.inviteError = false;
              // console.log(vm.inviteMessage, studentEmail );
            }
            // vm.inviteEmail = ''; //clear email in form
          });
        // }
      });
    },
    dispatchCollaborationThesis() {
      const data = {
        id: this.$route.params.id,
        newStatus: 'review',
        redirect: true,
      };
      this.$store.dispatch('updateCollaborationStatus', data);

      // setTimeout(() => {
      //   console.log(this.getId);
      var vm = this;
      // abhishek.singh@mindstask.com "info@excelerate.careers" "rajani@excelerate.careers"
      const mails = ['info@excelerate.careers', 'karoline@excelerate.careers'];
      for (const mail of mails) {
        const templateData = {
          toEmail: mail,
          firstName: vm.user.firstName,
          lastName: vm.user.lastName,
          param1: this.$route.params.id,
          param2: vm.collaborationDetails.title,
          template: 'Excelerate-Thesis submitted for review',
        };
        vm.$store.dispatch('sendMail', templateData);
      }

      document.getElementById('closeModal').click();
      // this.isModalOpen = false;
    },

    validateCharectors(type, topic) {
      if (type === 'title') {
        this.titleCharacters = this.collaborationDetails.title.length;
        if (this.titleCharacters > 100) {
          this.isTitleCharactersLimitExceeded = true;
        } else {
          this.isTitleCharactersLimitExceeded = false;
        }
      } else if (type === 'subtitle') {
        this.subTitleCharacters = this.collaborationDetails.subtitle.length;
        if (this.subTitleCharacters > 50) {
          this.isSubTitleCharactersLimitExceeded = true;
        } else {
          this.isSubTitleCharactersLimitExceeded = false;
        }
      } else if (type === 'mainContent') {
        // console.log(topic, this.collaborationDetails[topic] )
        this.mainContentCharacters = this.htmlToText(
          this.collaborationDetails[topic]
        ).length;
        if (this.mainContentCharacters > 500) {
          // this.isMainContentCharactersLimitExceeded = true;
        } else {
          // this.isMainContentCharactersLimitExceeded = false;
        }
      }
    },
    htmlToText(html) {
      let div = document.createElement('div');
      div.style.display = 'none';
      div.innerHTML = html;
      return div.innerText;
    },

    sendInvitationOnCollaboration() {
      var vm = this;
      var pendingCollaborationInvites =
        this.$store.state.collaboration.collaborationInvites;
      var existingMembers =
        this.$store.state.collaboration.collaborationMembers;
      var isAlreadyInvited = false;

      vm.inviteError = '';
      vm.inviteMessage = '';

      pendingCollaborationInvites.forEach(function (member, index) {
        if (member.invites.targetEmail === vm.inviteEmail) {
          isAlreadyInvited = true;
        }
      });

      existingMembers.forEach(function (member, index) {
        if (member.members.email === vm.inviteEmail) {
          isAlreadyInvited = true;
        }
      });

      if (isAlreadyInvited) {
        this.inviteError = 'User is already a member or invited';
      } else {
        db.collection('users')
          .where('email', '==', vm.inviteEmail)
          .get()
          .then(function (querySnapshot) {
            var collection = [];
            querySnapshot.forEach((doc) => {
              collection.push({ id: doc.id, user: doc.data() });
            });

            var data = {
              collaborationId: vm.$route.params.id,
              collaborationTitle: vm.collaborationDetails.title,
              sender: vm.user.firstName + ' ' + vm.user.lastName,
              senderEmail: vm.user.email,
              targetEmail: vm.inviteEmail,
            };

            if (collection.length > 0) {
              //if user already exists
              if (collection[0].user.role === 'Student') {
                //MAIL 'Student - Invitation Recieved, already a user' @firstName, lastName, param1: firstName (receiver), param2: collaboration.title, param3: collaboration.id
                const templateData = {
                  toEmail: vm.inviteEmail,
                  firstName: collection[0].user.firstName,
                  param1: vm.user.firstName,
                  param2: vm.user.lastName,
                  param3: vm.collaborationDetails.title,
                  param4: vm.$route.params.id,
                  template: 'Student - Invitation Recieved, already a user',
                };
                vm.$store.dispatch('sendMail', templateData);
                vm.$store.dispatch('inviteUserToCollabortion', data);
                vm.$store.dispatch(
                  'getCollaborationInvitesByCollabId',
                  vm.$route.params.id
                );

                vm.inviteMessage = 'Invite send.';
              } else {
                vm.inviteError = 'The user you are inviting is not a student'; //not sending or dispatching anything
              }
            } else {
              // user does not exist
              //MAIL 'Student - Invitation Recieved, not a user' @firstName (this user), lastName (this user), param1: collaboration.title
              const templateData = {
                toEmail: vm.inviteEmail,
                firstName: vm.user.firstName,
                lastName: vm.user.lastName,
                param1: vm.collaborationDetails.title,
                template: 'Student - Invitation Recieved, not a user',
              };
              vm.$store.dispatch('sendMail', templateData);
              vm.$store.dispatch('inviteUserToCollabortion', data);
              vm.$store.dispatch(
                'getCollaborationInvitesByCollabId',
                vm.$route.params.id
              );
              vm.inviteMessage = 'Invite send.';
            }
            vm.inviteEmail = ''; //clear email in form
          });
      }
    },
    preSubmit() {
      this.isModalOpen = !this.isModalOpen;
      this.firstTimeInvite = false;
      // this.collaborationDetails.stepCompleted = this.activeStep;
      const data = {
        id: this.$route.params.id,
        collaborationDetails: this.collaborationDetails,
        user: this.user,
      };
      this.$store.dispatch('updateCollaborationDetail', data);
      // this.$store.dispatch("updateCollaborationKeywords", {
      //   collaborationId: this.$route.params.id,
      //   keywords: this.collaborationDetails.keywords,
      // });
    },
    changeStatus(newStatus, redirect = false) {
      const data = {
        id: this.$route.params.id,
        newStatus: newStatus,
        user: this.user,
        redirect: redirect,
      };
      if (newStatus == 'published') {
        this.$store.dispatch('updateCollaborationKeywords', {
          collaborationId: this.$route.params.id,
          keywords: this.collaborationDetails.keywords,
        });
      }
      var existingMembers = this.collaborationMembers;
      // console.log(existingMembers);
      if (newStatus == 'deleted') {
        var vm = this;
        existingMembers.forEach(function (member, index) {
          const templateData = {
            toEmail: member.members.email,
            firstName: member.members.firstName,
            param1: vm.user.firstName,
            template: 'Student - Deleted Thesis Collaboration',
          };
          vm.$store.dispatch('sendMail', templateData);
        });
      }
      this.$store.dispatch('updateCollaborationStatus', data);
    },
    diff_weeks: function (dt2, dt1) {
      var diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
      diff /= 60 * 60 * 24 * 7;
      return Math.abs(Math.round(diff));
    },
    // validateStep:function(){
    //   let step = this.activeStep;
    //   let res = 0;
    //   switch (step) {
    //     case '1':
    //       if(this.titleCharacters==0 || this.subTitleCharacters==0||this.mainContentCharacters==0){
    //         res = 1
    //       }else{
    //         res = 0;
    //       }
    //       break;
    //     case '2':

    //       break;
    //     default:
    //       res=0;
    //       break;
    //   }
    //   return res;
    // }
    validateStep: async function () {
      let step = this.activeStep;
      switch (step) {
        case '1':
          // console.log(this.collaborationDetails,step)
          if (this.titleCharacters == 0) {
            this.errorBag.title = true;
            this.errorBag.submit = false;
          } else if (this.subTitleCharacters == 0) {
            this.errorBag.subtitle = true;
            this.errorBag.submit = false;
          } else if (this.mainContentCharacters == 0) {
            (this.errorBag.content = true), (this.errorBag.submit = false);
          } else {
            this.errorBag.submit = true;
          }

          break;

        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.tip {
  display: inline-block;
  width: 75%;
  font-size: 10px;
  margin-bottom: 0;
  color: rgb(173, 173, 173);
}
.embed-responsive {
  height: 262px;
}
.embed-responsive iframe {
  width: 100%;
  height: 261px;
}
</style>
<style>
.menu-container {
  border-top: 5px solid #6c2d88;
  border-radius: 5px;
}
label {
  color: #333;
}
.form-group label {
  color: #333;
  font-weight: bold;
}
.invite-member label {
  color: #4c2f71;
}
.color-first {
  color: #4c2f71 !important;
}

button {
  width: auto;
  padding: 0.5rem 3rem;
}
.small-text {
  font-size: 12px;
}

.limit-exceeded {
  border: 1px solid red;
}

.main-body input.form-control {
  margin-bottom: 0.4rem;
  padding: 1.6rem 1rem;
  color: #666;
}
.disabled {
  color: grey;
}
.modal-box {
  color: black;
  font-size: 0.8rem;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.modal-box .splash-line {
  padding: 0.2rem;
  margin-top: -2px;
  border: 1px solid transparent;
  border-radius: 20px 20px 0 0;
  background: transparent linear-gradient(270deg, #bc1e73 0%, #5a2871 100%) 0%
    0% no-repeat padding-box;
}
.preview .btn.btn-primary {
  background-color: #bc1e73;
}
.keyword {
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;

  /* color: #BC1E73;
  border: 1px solid #BC1E73;
  border-radius: 10px;
  margin: 0px 5px;
  padding: 2px 5px; */

  /* float: left; */
  /* list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 12px; */
}

.form-group h4 strong {
  color: #333;
  font-weight: 600;
}

.form-group p {
  color: #555;
}
.review h5 {
  font-size: 24px;
  font-weight: 400;
}

.review h5 b {
  font-weight: 600;
}

.review h6 {
  font-weight: 400;
}

.review h6 b {
  font-weight: 600;
}

.review {
  color: #111;
}
.list-item-counter {
  font-size: 18px !important;
  color: white !important;
  margin-right: 1rem;
  background-color: #bc1e73;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 32px;
}
</style>
<style>
.charactor-counter {
  float: right;
  width: 25%;
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
  color: rgb(173, 173, 173);
}
a.nav-link {
  /* color: #989898;
  padding: 0.5rem 2rem 0.5rem 0;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer; */
  padding: 0.5rem 2rem 0.5rem 0;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #d22d89;
  background: none;
  cursor: unset;
}
.tox-tinymce {
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

/* Checkbox Styles */
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e0dddd;
  border: 0px solid #000000;
  border-radius: 0px;
  pointer-events: none;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #da518a;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #0e6647d;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.9rem;
  margin-top: -0.9rem;
  background: #da518a;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.control-checkbox input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}
.review .para p {
  font-size: 14px !important;
  color: #333 !important;
  font-weight: lighter !important;
}
.review strong,
.preview strong,
.form-group strong {
  font-weight: 600;
}
.preview .btn.btn-primary {
  background-color: #bc1e73;
  border-color: #bc1e73;
}
.preview .btn {
  font-size: 16px;
}
.preview .btn-primary.focus,
.btn-primary:focus,
.preview .btn-primary:not(:disabled):not(.disabled).active,
.preview .btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #c7265e;
  border-color: #c7265e;
  box-shadow: 0 0 0 0.2rem rgb(206 73 119 / 29%);
}
.preview div {
  color: #111;
  font-size: 14px;
}
.modal-body {
  padding: 1.2rem 2rem;
}

.modal-content.modal-box {
  /* background: transparent linear-gradient(180deg, #F1F1F1 0%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat padding-box; */
  background: transparent linear-gradient(180deg, #f1f1f1 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
}
button.close {
  position: absolute;
  color: #fff;
  right: -34px;
  top: -39px;
  font-size: 42px;
  font-weight: 200;
}
.erow-cta {
  border-radius: 4px;
  padding: 10px 15px 10px;
  color: #fff;
  font-size: 14px;
  margin-bottom: -4px;
}
.erow-cta h4 {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 2px;
}
.collab-toolbar .btn {
  color: #5a2871;
}
.invite-area h6 {
  font-size: 24px;
}

.invite-member p {
  color: #999;
}

/* Updated Styles From Client */
.widget .widget-header h4 {
  font-weight: 600;
  font-size: 22px;
}
body,
html {
  height: 100%;
  background: #fff;
  color: #361d49;
  font-size: 14px;
  background-color: #fff;
  background-image: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAABkAAD/4QMsaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjYtYzE0OCA3OS4xNjQwMzYsIDIwMTkvMDgvMTMtMDE6MDY6NTcgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMS4wIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDNTg4NDFCOEUyMkUxMUVBQjQzRUY0NUU5QjIwRTVENSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDNTg4NDFCOUUyMkUxMUVBQjQzRUY0NUU5QjIwRTVENSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkM1ODg0MUI2RTIyRTExRUFCNDNFRjQ1RTlCMjBFNUQ1IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkM1ODg0MUI3RTIyRTExRUFCNDNFRjQ1RTlCMjBFNUQ1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgA/gABAwERAAIRAQMRAf/EAFgAAQEBAQAAAAAAAAAAAAAAAAMCBAoBAQAAAAAAAAAAAAAAAAAAAAAQAAIBAwUBAAAAAAAAAAAAAAABoWHREhFRkeETAhEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A7UAFwVYsBYCedY7AQBcFWLAJo9nwwHAXBViwC4fW0q4DAaAGw+dpdwG86x2A2H1tKuAwGgB9FsuEA2CrFgGwdJsA2j2fDAcB9FsuEAvnWOwEA0AXg6TYBQFwVYsBYCedY7AQD//Z);
  background-repeat: repeat-x;
  background-attachment: fixed;
}
.control {
  font-family: 'Poppins';
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 14px;
  color: #333 !important;
  font-weight: 400 !important;
}
.form-group {
  margin-bottom: 1rem;
  margin-top: 5px;
}
.main-body {
  border-right: 1px solid lightgrey;
}
.widget .widget-header h4 small {
  color: #d22d89;
  font-size: 70%;
}
.color-red {
  color: red !important;
}
.color-green {
  color: green !important;
}
</style>
