var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('editor',{attrs:{"api-key":"m2w1lu787dyjizvo3s7jq88kx0d29932605cvce948xywugr","output-format":"html","initial-value":_vm.value,"disabled":_vm.disabled,"init":{
      height: 450,
      menubar: false,      
      branding: false,
      plugins: ['lists', 'paste'],
      paste_as_text: true,
      statusbar: false,
      toolbar: 'formatselect bold italic |  numlist bullist',
    }},on:{"input":_vm.updateValue},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }